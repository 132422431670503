.container {
    width: 100%;
  

  .datePicker {
    input{
      color: #C20200;
    }
  }

  .week {
    display: flex;
    flex-direction: row;
    vertical-align: baseline;
    float: right;
    padding-top: 15px;
    padding-bottom: 15px;
    :nth-child(2){
      width: 160px;
    }
    :nth-last-child(2){
      width: 160px;
    }
  }
  .day {
    text-align: center;
    width: 175px;
  
    }
    .selected{
        color: #C20200;;
    }
  .calenderArrow {
    color: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

        &:hover {
        color: #C20200;
        }
  
    }
}

.appointments {
  width: 100%;

  .datePicker {
    input{
      color: #C20200;
    }
  }

  .week {
    width: 80%;
    display: flex;
    flex-direction: row;
    vertical-align: baseline;
    float: right;
    padding-top: 15px;
    padding-bottom: 15px;
    :nth-child(2){
      width: 13.8%; // 14.3 - 0.5 for the arrow
    }
    :nth-last-child(2){
      width: 13.8%; // 14.3 - 0.5 for the arrow
    }
  }
  .day {
    text-align: center;
    width: 14.3%; // =100/7, which would give equal size to each day for any resolution
  }
  .selected{
    color: #C20200;;
  }
  .calenderArrow {
    color: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 0.5%;

    &:hover {
    color: #C20200;
    }

  }
}