@import 'scss/base/sizes.scss';
@import 'scss/base/colors.scss';


.dialogBody {
  padding: 0 25px 25px;

  .fields {
    display: flex;
    flex-direction: column;
  }

  .buttonSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .dialogButtonContainer {
    flex: 1;
    margin: 0 10px;

    &:first-child {
      margin: 0;
    }

    &:last-child {
      margin: 0;
    }
  }

  .repeatContainer {
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .openCloseContainer {
    display: flex;
    margin: 15px 0px;
  }

  .selectContainer {
    margin: 0 15px;
    flex: 1;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .deleteButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #B64040;
    color: white;
    border-radius: 5px;
    padding: 10px;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
  }

  .buttomButtonSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    padding-top: 20px;
    width: 100%;
    height: 60px;
  }  

  .repeatSchedule {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-size: 20px;
    padding: 35px;
    .checkBoxArea{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .checkBoxArea > *{
      padding-left: 15px;
      padding-right: 15px;

    } 

  }

  .button {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .topMargin {
    margin-top: 10px;
  }

  .startEndDatesContainer {
    display: flex;
  }
}

.rightContent {
  justify-content: flex-end;
}

.dialogCondition {
  display: inline-flex;
  flex-grow: 1;
  .dialogLink{
    text-align: center;
    width: 100%;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
}

.repeatSchedule {
  display: flex; 
  flex-direction: row;
  height: 100%; 
  align-items: center; 
  font-size: 20px; 
  padding: 10px;
  justify-content: space-between;
}

.closeOrDelete {
  color: red;
}

.defaultBtn {
  width: 100%;
}

.saveOrUpdate {
  font-style: normal;
  font-weight: bold;
  font-size: $font-size-link;
  line-height: 1.6875rem;
  text-transform: uppercase;
  color: #4052B6 !important;
  text-decoration: none;
}

.closeOrDelete {
  font-style: normal;
  text-decoration: none;
  font-weight: bold;
  font-size: $font-size-link;
  line-height: 1.6875rem;
  text-transform: uppercase;
  color: #A44242 !important;
  cursor: pointer;
}

.dialogCondition {
  display: inline-flex;
  flex-grow: 1;
  
  .dialogLink {
    flex-grow: 1;
    text-align: center;
  }
}
