@import 'scss/base.scss';

.weekEvent {
  background: white;
  height: 100%;
  border-left: 7px solid #27ae60;
  padding: 5px;
  border-top: solid 1px #e5e5e5;
  border-bottom: solid 1px #e5e5e5;
  border-right: solid 1px #e5e5e5;
  border-radius: 4px;
  position: relative;

  &.1 { // Unconfirmed
    border-left: 7px solid #ED5653;
  }
  &.2 { // Need Moved
    border-left: 7px solid #BC67DC;
  }
  &.3 { // Confirmed
    border-left: 7px solid #1AAF5D;
  }
  &.6 { // Arrived
    border-left: 7px solid #287DF0;
  }
  &.7 { // Departed
    border-left: 7px solid #239BDE;
  }
  &.8 { //Billed
    border-left: 7px solid #4FCCF4;
  }
  &.9 { //Follow up Scheduled
    border-left: 7px solid #F3CA3E;
  }
  &.10 { //No-show
    border-left: 7px solid #F49A40;
  }

  &.location2 {
    background: red;
  }

  .title {
    height: fit-content;
    white-space: normal;
    line-height: normal;
    font-weight: bold;
    font-size: 10px;
    color: #444444;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .type {
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;
    color: #444444;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .patient {
    margin-top: 4px;
    font-size: 10px;
    line-height: 12px;
    color: #26315a;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;

    &.needReferral {
      color: red;
      font-weight: 800;
    }
  }

  .availableDoctors {
    margin-top: 4px;
    font-size: 10px;
    line-height: 12px;
    color: #26315a;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
  }

  .cancellationList {
    margin: $margin-3px 0;
    font-size: $font-size-tiny;
    color: #26315a;
    overflow: hidden;
  }

  .remove {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 0;
    * {
      width: 0.7em;
      height: 0.7em;
    }
  }
}
