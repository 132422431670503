.iframe {
  background: #f1f3ff;
  border-radius: 0.3125rem;
  padding: 0.75rem;
  height: 100%;
  width: 100%;
}
.btn {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 200px;
}
.scaledFrame {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
