@import 'scss/base/colors.scss';

.locationInfo {
  border-bottom: solid 1px $color-light-grey-border;
  padding: 10px;

  &.active {
    background-color: #93a3fb62;
  }

  .appointmentInfoLocContainer {
    display: flex;
    align-items: center;

    .locationName {
      font-weight: bold;
      flex: 1;
      padding-right: 5px;
      font-size: 12px;
      margin: 0;
      margin-bottom: 10px;
    }

    .viewButton {
      background-color: #4052b6;
      border: solid 1px #4052b6;
      border-radius: 100px;
      height: 30px;
      text-transform: none;
      color: white;
      font-weight: bold;
      padding: 0px 25px;
      font-size: 12px;

      &:hover {
        background-color: transparent;
        color: $color-link;
      }
    }

    .mailIcon {
      &:hover {
        color: $color-link;
        cursor: pointer;
      }
    }
  }

  .appointmentInfoTypeContainer {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    text-align: center;
    margin-top: 5px;

    .apptDays {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      font-size: 12px;

      p {
        margin: 0 5px;
      }
    }

    .apptSummary {
      flex: 1;

      .apptDays {
        font-weight: bolder;
      }

      .apptLabel {
        font-weight: bolder;
        position: relative;
        white-space: nowrap;
        font-size: 12px;
        text-align: center;
        width: auto;
        height: 12px;
        background-position: left;
        background-repeat: no-repeat;
        display: flex;
        margin-right: 15px;
        content: '';
        align-items: center;

        &.active {
          color: #61b947;
          background-image: url('../../../../assets/svgs/greenCheck.svg');
          padding-left: 18px;
        }

        &.notActive {
          color: #e74040;
          background-size: contain;
          background-image: url('../../../../assets/svgs/closeButton.svg');
          padding-left: 20px;
        }
      }
    }
  }
}
