@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.topBarContainer {
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .headerContainer {
    padding: 15px;
  }

  .hourLabel {
    margin: 0;
    padding: 0 15px;
    text-align: center;
    min-height: $appointment-calendar-block-height;
    max-height: $appointment-calendar-block-height;
    line-height: $appointment-calendar-block-height;
    background-color: #93a3fb62;
    font-size: 14px;
    border-bottom: solid 1px $color-light-grey-border;
  }

  .personalTime {
    display: flex;
    padding: 10px;
    justify-content: right;
  }

  .appointmentPageContainer {
    display: flex;
    flex: 1;
    overflow: hidden;
  }

  .availabilityByTypeContainer {
    display: flex;
  }

  .filterControl {
    display: flex;
    align-items: flex-start;
    width: $appointment-calendar-block-width;
    justify-content: right;
  }

  .appointmentsByTypeContainer {
    display: flex;
    height: 100%;
    flex: 1;
    overflow-x: scroll;
  }

  .dailyAppointmentContainer {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    .dateNavHeader {
      margin: 10px;
      text-align: center;
      margin-left: 70px;
      border-bottom: 1px solid #a3a3a3;
      padding-bottom: 10px;
    }
  }

  .locationSelector {
    padding-bottom: 10px;
    padding-left: 15px;
  }

  .locationAndDateNavigator {
    display: flex;
    flex-direction: column;

    .operationInProgress {
      margin: 15px;
    }

    .dayNavigator {
      display: flex;
      margin: 25px 10px;

      .datePicker {
        flex: 1;
        margin: 0 15px;
      }

      .calenderArrow {
        color: grey;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          color: $color-hover;
        }
      }
    }
    .weekOf {
      text-align: center;
      text-decoration: underline;
      font-size: 16px;
    }
  }
}
