@import 'scss/base/sizes.scss';
@import 'scss/base/colors.scss';

.doctorSelection {
  position: relative;
  .selection {
    width: 300px;
  }
  .addSignature {
    position: absolute;
    bottom:0px;
    left: 350px;
    width: 200px;
    margin-left: 20px;
    display: flex;
    align-content: baseline;
    height: 30px;
  }
}
.container {
  width: 100%;
  
}

.main {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: row;

  .previewContainer {
    display: flex;
    flex: 1;
  }

  .formContainer {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
  }

  .header {
    width: 100%;
    border-bottom: solid 1px #e5e5e5;
    padding: $padding-large $padding-2larger;

    .headerLogo {
      max-width: 317px;
    }

    .headTabs {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-content: center;

      p {
        margin: 0px;
        font-size: 21px;
      }

      .tabs {
        margin-left: $margin-large;
        display: flex;
        flex-wrap: wrap;

        > div {
          margin-bottom: 10px;
        }
      }

      .tab {
        font-style: normal;
        font-weight: bold;
        font-size: $font-size-link;
        line-height: 1.6875rem;
        text-transform: uppercase;
        text-decoration: none;
        color: $color-background-main !important;
        cursor: pointer;
        padding: 5px 10px;
        border-radius: 4px;

        &:not(:last-child) {
          margin-right: $margin-larger;
        }

        &.active {
          background-color: $color-primary;
          color: white !important;
        }
      }
    }
  }

  .questions {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    padding: 0 $padding-2larger;
    margin-top: $margin-large;
    // min-width: 900px;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: $padding-large $padding-2larger;
    box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.15);

    .button {
      &:first-child {
        margin-right: auto;
      }
      &:last-child {
        margin-left: $margin-larger;
      }
    }
  }
}

.dialogBody {
  .fields {
    display: flex;
    flex-direction: column;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 2.6875rem;

    .marginLeft {
      margin-left: 1.25rem;
    }
  }
}

.withPadding {
  padding: 0 20px 20px 20px;
}


.container {
  padding: 20px;

  .text {
      font-size: 22px;
      padding: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
  }
  
  .buttons {
      padding-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
  }
}
