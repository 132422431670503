@import 'scss/base/colors.scss';

.appointmentInfoContainer {
  p {
    margin: 0;
    font-size: 9px;
  }
  pointer-events: all;
  padding: 5px;
  border: solid 1px $color-dark-grey-border;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: white;
  position: relative;

  .appointmentNotes {
    border-top: 1px solid;
    border-bottom: 1px solid $color-dark-grey-border;
    padding-top: 5px;
    padding-bottom: 10px;
    font-weight: bolder;
  }

  .appointmentFormsAndDocs {
    display: flex;

    .appointmentForms,
    .appointmentDocuments {
      display: flex;
      flex-direction: column;
      align-items: start;
      width: 50%;
    }

    .appointmentForms {
      border-right: solid 1px $color-dark-grey-border;
      margin-right: 5px;
    }

    .document {
      text-align: left;
      border-radius: 25px;
      margin-top: 7px;
    }

    .form,
    .formCompleted {
      text-align: left;
      border-radius: 25px;
      margin-top: 2px;
      color: $color-confirmed
    }
  }

  .appointmentInfoFlexRow {
    width: 100%;
    display: flex;
    padding-bottom: 5px;
    justify-content: space-between;

    .appointmentInfoFlexColumn {
      display: flex;
      flex-direction: column;

      .appointmentPatient {
        margin-top: -4px;
      }
      .hasReferralDoc {
        color: $color-confirmed;
      }
      .noReferralDoc {
        color: $color-not-confirmed;
      }
    }

    .appointmentInfoBorder {
      border-left: 1px solid $color-dark-grey-border;
    }

    .appointmentHours {
      font-weight: bolder;
      color: #4052b6;
    }

    .appointmentType {
      font-weight: bolder;
      margin: 3px 0px;
      font-size: 10px;
    }

    .appointmentDoctor {
      margin-bottom: 2px;
      text-align: center;
    }

    .appointmentPhoneNumber {
      margin-top: 3px;
    }

    .appointmentStatusButton {
      border-width: 0;
      background-color: white;
    }

    .appointmentStaffNotes {
      border-width: 0;
      background-color: white;
    }

    .appointmentStatus {
      margin-bottom: 2px;

      &::before {
        content: '';
        width: 10px;
        height: 10px;
        display: inline-block;
        background-color: white;
        border-radius: 100%;
        margin-right: 3px;
        vertical-align: middle;
      }

      &.1 {
        // Unconfirmed
        color: $color-not-confirmed;

        &::before {
          background-color: $color-not-confirmed;
        }
      }

      &.2 {
        // Need Moved
        color: $color-need-moved;

        &::before {
          background-color: $color-need-moved;
        }
      }

      &.3 {
        // Confirmed
        color: $color-confirmed;

        &::before {
          background-color: $color-confirmed;
        }
      }

      &.4 {
        // Cancelled
        color: $color-cancelled;

        &::before {
          background-color: $color-cancelled;
        }
      }

      &.6 {
        // Arrived
        color: $color-arrived;

        &::before {
          background-color: $color-arrived;
        }
      }

      &.7 {
        // Departed
        color: $color-departed;

        &::before {
          background-color: $color-departed;
        }
      }

      &.8 {
        //Billed
        color: $color-billed;

        &::before {
          background-color: $color-billed;
        }
      }

      &.9 {
        //Follow up Scheduled
        color: $color-follow-up-scheduled;

        &::before {
          background-color: $color-follow-up-scheduled;
        }
      }

      &.10 {
        //No-show
        color: $color-no-show;

        &::before {
          background-color: $color-no-show;
        }
      }
      &.11 {
        //Not Performed
        color: $color-not-performed;

        &::before {
          background-color: $color-not-performed;
        }
      }
    }

    .edit {
      padding: 0;
      margin-right: 5px;
    }

    .delete {
      padding: 0;
    }
  }
}

.filtered {
  background-color: $color-background-filtered;
  opacity: 0.8;
}

.highlighted {
  background-color: $color-secondary;
  border: 1px solid $color-border-secondary;
}

.appointmentInfoContainer:hover {
  border: solid 1px $color-border-secondary;
  height: auto !important;
  z-index: 10;
}

.cancelled {
  background-color: $color-day-exception;
}
