@import 'scss/base/colors.scss';

.main {
  .dot {
    background: $color-background-main;
    border-radius: 50%;
    height: 0.375rem;
    width: 0.375rem;
    &:not(:first-child) {
      margin-top: 0.1875rem;
    }
  }
}
